import React, { Component } from 'react';
import styled from 'styled-components';
import clipperScript from '../assets/PersonalUse_Clipper_Script_fat.ttf';
import heart from '../assets/heart2.png';

const WhiteBoxWrapper = styled.div`
  border-style: solid;
  border-color: #AB1F1F;
  border-width: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 380px;
  height: 380px;
  background-color: #ffffff;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  box-shadow: -5px -5px 6px 0 rgba(0, 0, 0, 0.16), 5px 5px 6px 0 rgba(0, 0, 0, 0.16);
  margin: -190px 0 0 -190px;
  animation: spin infinite 20s linear;

  @keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`;

const Text = styled.p`
  @font-face {
    font-family: 'Clipper Script';
    src: url(${clipperScript});
  }
  font-family: 'Clipper Script';

  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  text-align: center;
  width: 400px;
  height: ${props => props.big ? 164 : 120}px;
  margin: ${props => props.big ? -55 : 80}px 0 0 -200px;
  font-size: ${props => props.big ? 100 : 45}px;
  color: #707070 !important;
`;

const Heart = styled.div`
  background-image: url(${heart});
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 120px;
  width: 120px;
  margin: -180px 0 0 -60px;
  transform: rotate(26deg);
`;

class InfoBox extends Component {
  render() {
    return (
      <div>
        <WhiteBoxWrapper />
        <Heart />
        <Text big>My Love</Text>
        <Text small>I love you</Text>
      </div>
    );
  }
}
export default InfoBox;