import React, { Component } from 'react';
import styled from 'styled-components';
import background from '../assets/bg.png';
import cat from '../assets/cat.png';
import hearts from '../assets/Hearts.png';
import InfoBox from './InfoBox';

const Background = styled.div`
  background-image: url(${background});
  width: 100%;
  height: 100vh;

  @font-face {
    font-family: 'Clipper Script';
    src: url('./assets/PersonalUse_Clipper_Script_fat.ttf');
  }
  font-family: 'Clipper Script';
`;

const Cat = styled.div`
  background-image: url(${cat});
  background-size: contain;
  width: 145px;
  height: 154px;
  position: absolute;
  bottom: 0px;
  left: 50px;
`;

const Hearts = styled.div`
  background-image: url(${hearts});
  background-size: contain;
  width: 994px;
  height: 907px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -453px 0 0 -497px;
`;

class Screen extends Component {
  render() {
    return (
      <Background>
        <Cat />
        <Hearts />
        <InfoBox />
      </Background>
    );
  }
}
export default Screen;